/* eslint-disable */
import {Style2} from "../responsive-lib";

export const section6 = new Style2('section6')
    .addMediaQuery({orientation: "portrait"}, {
        ".hp-section6-container": {
            width: "1440px",
            padding: "142px 0px 114px 0px",
            gap: "15px"
        },
        ".hp-section6-container > .h1-js": {
            width: "335px"
        },
        ".hp-section6 .pyramid-width-green-side": {
            width: "60px"
        },
        ".hp-section6 .fogginess-width-cloud": {
            width: "81px"
        },
        ".hp-section6 .rotation": {
            width: "22px"
        },
        ".hp-section6 .pyramid-small-with-green-side": {
            width: "45px"
        },
        ".hp-section6 .orb-dark2": {
            width: "56px"
        }

    })
    .addMediaQuery({orientation: "landscape"}, {
        ".hp-section6-container": {
            width: "1440px",
            padding: "219px 0px 117px 0px",
            gap: "72px"
        },
        ".hp-section6-container > .h1-js": {
            width: "931px"
        },
        ".hp-section6 .fogginess-width-cloud": {
            width: "117px"
        },
        ".hp-section6 .pyramid-width-green-side": {
            width: "110px"
        },
        ".hp-section6 .rotation": {
            width: "52px"
        },
        ".hp-section6 .pyramid-small-with-green-side": {
            width: "55px"
        },
        ".hp-section6 .cylinder": {
            width: "77px"
        },
        ".hp-section6 .azi-dark2": {
            width: "180px"
        },
        ".hp-section6 .orb-dark2": {
            width: "95px"
        }

    })
    .update()
