/* eslint-disable */
import {Style2} from "../responsive-lib";

export const section1 = new Style2('section1')
    .addMediaQuery({orientation: "portrait"}, {
        ".home-hero-banner__wrapper": {
            paddingTop: "80px",
            paddingBottom: "54px",
        },
        ".home-hero-banner__description": {
            width: "272px",
        },
        ".home-hero-banner__wrapper > .cta-button": {
            marginTop: "20px"
        }

    })
    .addMediaQuery({orientation: "landscape"}, {
        ".home-hero-banner__wrapper": {
            paddingTop: "80px",
            paddingBottom: "54px",
        },
        ".home-hero-banner__description": {
            width: "455px",
        }
    })
    .update()
