/* eslint-disable */
import {Style2} from "../responsive-lib";

export const section4 = new Style2('section4')
    .addMediaQuery({orientation: "portrait"}, {
        ".hp-section4 > .mobile": {
            padding: "0px 20px"
        },
        ".hp-section4 > .mobile >.h1-js": {
            padding: "63px 0px"
        },
        ".hp-section4 > .mobile > .line": {
            padding: "30px 0px 30px 12px",
            gap: "30px"
        },
        ".hp-section4 > .mobile > .line > .letter-container > .letter": {
            height: "113px"
        },
        ".hp-section4 > .mobile > .line > .letter-container > .hint": {
            width: "184px",
            gap: "3px"
        },
        ".hp-section4 > .mobile > .line > .letter-container > .hint > .CTA-js": {
            marginTop: "4px"
        },
        ".hp-section4  .cone-dark": {
            width: "100px",
            height: "100px",
            minWidth: "100px",

        },
        ".hp-section4  .orb-dark": {
            width: "55px",
            height: "55px",
            minWidth: "55px",

        },

    })
    .addMediaQuery({orientation: "landscape"}, {
        ".hp-section4 > .desktop": {
            padding: "109px"
        },
        ".hp-section4 > .desktop > .line": {
            padding: "60px 0"
        },
        ".hp-section4 > .desktop > .line .letter-container": {
            height: "200px",
            width: "200px"
        },
        ".hp-section4 > .desktop > .line .letter-container .letter-3d": {
            height: "200px",
        },

        ".hp-section4 > .desktop > .line  .letter-simple": {
            height: "130px"
        },
        ".hp-section4 > .desktop > .line > div >.h6-js": {
            marginTop: "30px"
        },
        ".hp-section4 > .desktop > .line > div > .hint": {
            padding: "25px 20px",
            width: "254px",
            gap: "3px"
        },
        ".hp-section4 > .desktop > .line > div > .hint > .CTA-js": {
            marginTop: "4px"
        },
        ".hp-section4  .orb-dark": {
            width: "94px",
            height: "94px",
            minWidth: "100px",

        },
        ".hp-section4  .cone-dark": {
            width: "160px",
            height: "160px",
            minWidth: "100px",

        },
        ".hp-section4  .forgginess": {
            width: "117px",
            height: "26px",
            minWidth: "100px",

        },
        ".hp-section4  .orb-deg": {
            width: "101px",
            height: "37px",

        },
        ".hp-section4  .azi-dark": {
            width: "180px",
            height: "50px",

        },
        ".hp-section4  .rotation-width-deg": {
            width: "101px",
            height: "37px",

        },
        ".DisplayTitle-hr > div": {
            width: "371px",
            height: "16px"
        },

    })
    .update()
