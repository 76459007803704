/* eslint-disable */
import {Style2} from "../responsive-lib";

export const section2 = new Style2('section2')
    .addMediaQuery({orientation: "portrait"}, {
        ".hp-section2 .slides .slide": {
            paddingTop: "60px",
            paddingBottom: "60px",
            gap: "15px"
        },
        ".hp-section2 .slides .slide .BodyL-js": {
            width: "288px"
        },
        ".hp-section2 .slides .slide .h2-js": {
            width: "336px"
        },
        ".hp-section2 .slides .slide > .options-container": {
            gap: "40px"
        },
        ".hp-section2 .slides .slide > .options-container > .option": {
            gap: "5px",
            width: "56px"
        },
        ".hp-section2 .slides .slide > .options-container > .option > img": {
            width: "18px"
        }

    })
    .addMediaQuery({orientation: "landscape"}, {
        ".hp-section2 .slides .slide": {
            padding: "94px",
            gap: "43px"
        },
        ".hp-section2 .slides .slide .BodyL-js": {
            width: "419px"
        },
        ".hp-section2 .slides .slide > .options-container": {
            gap: "65px"
        },
        ".hp-section2 .slides .slide > .options-container > .option": {
            gap: "18px"
        },
        ".hp-section2 .slides .slide > .options-container > .option > img": {
            width: "21px"
        }
    })
    .update()
