/* eslint-disable */
import {Style2} from "../responsive-lib";

export const section5 = new Style2('section5')
    .addMediaQuery({orientation: "portrait"}, {
        ".hp-section5": {
            padding: "100px 0px 0px 0px"
        },
        ".hp-section5 > .h2-js": {
            width: "292px",
            marginBottom: "52px"
        }


    })
    .addMediaQuery({orientation: "landscape"}, {
        ".hp-section5 > .h2-js": {
            width: "802px"
        },
        ".hp-section5 .cone-simple": {
            width: "100px"
        },
        ".hp-section5 .pyramid-small": {
            width: "56px"
        }
    })
    .update()