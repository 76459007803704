/* eslint-disable */
import {Style2} from "../responsive-lib";

export const typoghraphy = new Style2('typoghraphy')
    .addMediaQuery({orientation: "portrait"}, {
        ".h1-js": {
            fontSize: "48px"
        },
        ".h2-js": {
            fontSize: "40px"
        },
        ".h6-js": {
            fontSize: "20px"
        },
        ".BodyL-js": {
          fontSize: "16px"
        },
        ".BodyM-js": {
            fontSize: "14px"
        },
        ".BodyS-js": {
            fontSize: "13px"
        },
        ".CTA-js": {
            fontSize: "14px"
        },
    })
    .addMediaQuery({orientation: "landscape"}, {
        ".h1-js": {
            fontSize: "90px"
        },
        ".h2-js": {
            fontSize: "70px"
        },
        ".h6-js": {
            fontSize: "18px"
        },
        ".BodyL-js": {
            fontSize: "20px"
        },
        ".BodyM-js": {
            fontSize: "16px"
        },
        ".BodyS-js": {
            fontSize: "14px"
        },
        ".CTA-js": {
            fontSize: "16px"
        },
        ".DisplayTitle": {
            fontSize: "123px"
        },
    })
    .update()
