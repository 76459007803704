/* eslint-disable */
import {Style2} from "../responsive-lib";

export const global = new Style2('global')
    .addMediaQuery({orientation: "portrait"}, {
        ".container-js": {
            width: "375px"
        }

    })
    .addMediaQuery({orientation: "landscape"}, {
        ".container-js": {
            width: "1440px"
        }
    })
    .update()